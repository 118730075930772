import palette from '../palette';

export default {
	root: {
		fontFamily: 'Gilroy',
		// backgroundColor: 'rgba(255, 255, 255, 0.9)',
	},
	input: {
		'&::placeholder': {
			opacity: 1,
			color: palette.text.secondary
		}
	}
};
