import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme } from '@material-ui/core';

import 'react-perfect-scrollbar/dist/css/styles.css';
import { configureStore } from './store';
import routes from './routes';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';

import './assets/scss/main.scss';
import { baseTheme } from './theme';

const history = createBrowserHistory({
	basename: '/demoGuideLiveness/Pm'
});

function App() {
	const query = window.location.search;
	const params = new URLSearchParams(query);

	const { store } = configureStore({ locale: params.get('locale') });
	const [direction] = useState('ltr');
	const theme = createTheme(baseTheme);

	return (
		<StoreProvider store={store}>
			<ThemeProvider theme={theme}>
				<StylesProvider direction={direction}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<Router history={history}>
							<ScrollReset />
							{renderRoutes(routes)}
						</Router>
					</MuiPickersUtilsProvider>
				</StylesProvider>
			</ThemeProvider>
		</StoreProvider>
	);
}

export default App;
