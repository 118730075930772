export default {
	app_name: 'eKYC',
	btn: {
		next: 'next',
	},
	overview: {
		title: 'eKYC - Step 2',
		caption: 'STEP 2',
		header: 'Scan your face',
		subheader: 'Scan you face to identify your ID Card',
		suggestion: {
			header: 'Step 2 from 3 :',
			title: 'Selfie with Facial',
			item: 'Make sure your face is on even lighting and not covered',
			item_2: 'Center your face and stay still',
			item_3: 'Blink your eye 1 time to take a picture',
		},
		bigtext: {
			h1: 'Selfie facial for self verification',
			h2: 'Please follow the instruction'
		},
		light: {
			first: 'Ensure the',
			sec: 'Light and Brightness',
			third: ' '
		},
		face: {
			first: 'Keep the facial',
			sec: 'within the frame provided',
			third: 'with clear background',
			four: ' '
		},
		cap: {
			first: 'Take off',
			sec: 'the hat, cap, glasses or',
			third: 'any objects to cover the facial'
		},
		remark: 'Your information is securely stored',
		start: 'Start to Selfie'
	},
};
