export default {
	app_name: 'eKYC',
	btn: {
		next: 'ถัดไป',
	},
	overview: {
		title: 'eKYC - Step 2',
		caption: 'ขั้นตอนที่ 2',
		header: 'สแกนใบหน้า',
		subheader: 'สแกนใบหน้าเพื่อพิสูจน์ความเป็นบุคคล',
		suggestion: {
			header: 'ขั้นตอนที่ 2 จาก 3',
			title: 'เซลฟีใบหน้า',
			item: 'อยู่ในบริเวณที่มีแสงสว่างเพียงพอ และ ไม่ใส่หมวก แว่นตา  ไม่มีอะไรมาปิดบังใบหน้า',
			item_2: 'หน้าตรง และวางใบหน้าให้อยู่ในกรอบที่กำหนด',
			item_3: 'กระพริบตา  1 ครั้งเพื่อถ่ายภาพ',
		},
		bigtext: {
			h1: 'เซลฟีใบหน้าเพื่อพิสูจน์บุคคล',
			h2: 'โดยทำตามคำแนะนำดังนี้'
		},
		light: {
			first: 'อยู่ในบริเวณที่มี',
			sec: 'แสงสว่าง',
			third: 'เพียงพอ'
		},
		face: {
			first: 'หน้าตรงอยู่',
			sec: 'ในกรอบ',
			third: 'ที่กำหนด',
			four: 'และไม่มีบุคคลหรือสัตว์อยู่ด้านหลัง'
		},
		cap: {
			first: 'ไม่สวมหมวก ไม่สวมแว่นตา',
			sec: 'และ',
			third: 'ไม่ปิดบังใบหน้า'
		},
		remark: 'ข้อมูลของคุณจะถูกจัดเก็บอย่างปลอดภัย',
		start: 'เริ่มเซลฟีใบหน้า'
	},
};
