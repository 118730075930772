import * as actionTypes from 'src/actions';

const initialState = {
	loggedIn: false,
	user: {
		role: 'GUEST' // ['GUEST', 'USER', 'ADMIN']
	},
};

const sessionReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SESSION_LOGIN: {
			return {
				...state,
				loggedIn: true,
				user: action.payload.user,
				sponsor: action.payload.sponsor
			};
		}

		default: {
			return state;
		}
	}
};

export default sessionReducer;
