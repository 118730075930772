import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';

export default [
	{
		path: '/',
		component: AuthLayout,
		routes: [
			{
				path: '/',
				exact: true,
				component: lazy(() => import('src/views/Overview'))
			},
			// {
			// 	component: () => <Redirect to="/errors/error-404" />
			// }
		]
	},
	{
		path: '/errors',
		component: ErrorLayout,
		routes: [
			{
				path: '/errors/error-401',
				exact: true,
				component: lazy(() => import('src/views/Error401'))
			},
			{
				path: '/errors/error-404',
				exact: true,
				component: lazy(() => import('src/views/Error404'))
			},
			{
				path: '/errors/error-500',
				exact: true,
				component: lazy(() => import('src/views/Error500'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	},
];
