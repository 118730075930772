import {
	applyMiddleware,
	createStore,
	combineReducers,
	compose
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import storageSession from 'redux-persist/lib/storage/session';
import rootReducer from 'src/reducers';
import {
	loadTranslations,
	setLocale,
	syncTranslationWithStore,
	i18nReducer
} from 'react-redux-i18n';
import moment from 'moment';

// const loggerMiddleware = createLogger();

import en from '../i18n/en';
import th from '../i18n/th';

const translationsObject = {
	en,
	th
};
const persistConfig = {
	key: 'auth',
	storage,
	// storage: storageSession
};

const middlewares = [thunkMiddleware]; // loggerMiddleware
const middlewareEnhancer = composeWithDevTools(
	applyMiddleware(...middlewares)
);
const enhancers = [middlewareEnhancer];
const composedEnhancers = compose(...enhancers);
const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = null;
let persistor = null;
let i18nStore = null;

export default function configureStore(preloadedState = {}) {
	// const store = createStore(rootReducer, preloadedState, composedEnhancers);
	//	Update store for persist data - Store for all
	if (store == null) {
		store = createStore(
			persistedReducer,
			preloadedState,
			composedEnhancers
		);
		persistor = persistStore(store);

		//	Store for i18n
		i18nStore = createStore(
			combineReducers({
				rootReducer,
				i18n: i18nReducer
			}),
			applyMiddleware(...middlewares)
		);
		syncTranslationWithStore(i18nStore);
		i18nStore.dispatch(loadTranslations(translationsObject));
		i18nStore.dispatch(setLocale(preloadedState.locale || 'en'));
		console.log(preloadedState.locale);
	}

	//	Moment js
	moment.locale('th');

	return { store, i18nStore, persistor };
}
